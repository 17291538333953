@tailwind base;
@tailwind components;
@tailwind utilities;
.slide-in-left {
  animation: slideInLeft 1s forwards;
  opacity: 100;
}

@keyframes slideInLeft {
  from {
    opacity: 75;
    transform: translateX(-100%);
  }
  to {
    opacity: 75;
    transform: translateX(0);
  }
}

.slide-in-left {
  animation: slideInLeft 2s forwards;
  opacity: 0;
}

@keyframes slideInLeft {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
